import React from "react";

const Footer = (_props: any) => {
    return <div id='footer' className='attributions'>
        <span>Images by&nbsp;</span>
        <a href="https://www.freepik.com/free-vector/turing-pattern-structure-organic-lines-background-design_10016725.htm#page=15&query=seamless%20background&position=20&from_view=search&track=sph">starline</a>
        <span>&nbsp;and&nbsp;</span>
        <a href="https://www.freepik.com/free-photo/white-texture_946226.htm#page=2&query=seamless%20background%20paper&position=2&from_view=search&track=ais">bedneyimages</a>
        <span>&nbsp;on Freepik</span>
        <div>All Rights Reserved - Michael Kroeze  &#169;2023</div>
        <div id='deb'>&nbsp;</div>
    </div>
};

export default Footer;