import React from "react";
import PositionLink from "./position-link";

const PositionLinks = (props: any) => {
    let links: any = [];
    props.positions.map((position: any, index: number) => {
        links.push(<PositionLink position={position} index={index}/>);
    });
    return <>{links}</>
}

export default PositionLinks;