import {getEmployer, getPosition, resize, setActiveLink} from "../utils";
import {LoaderFunctionArgs, useLoaderData, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PositionLinks from "./position-links";
import {IPosition} from "../interfaces/iposition";

export const employerLoader = async ({params}: LoaderFunctionArgs) => {
    let data: any;
    const response = await getEmployer(params.employer);
    if (response.status === 200) {
        data = response.data
        const positions = [];
        for (const link of data.positions) {
            const response2 = await getPosition(params.employer, link);
            positions.push(response2.data as IPosition);
        }
        data.positionData = positions;
    }
    return {data};
};

const Employer = (props: any) => {
    const navigate = useNavigate();
    const [employerState, setEmployerState] = useState(props.employer || 'career')
    const {employer} = useParams();
    const {data}: any = useLoaderData();
    const backButtonClickHandler = () => {
        navigate(-1);
    }

    useEffect(() => {
        resize().then();
    }, []);

    if (employer !== employerState) {
        setActiveLink(employer);
        setEmployerState(employer);
    }

    return <>
        <h2>{data.name}</h2>
        <span><i>{data.blurb}</i></span>
        <hr/>
        <ul>
            <PositionLinks positions={data.positionData}/>
        </ul>
        <br/>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <button type="button"
                            className='btn btn-primary'
                            onClick={backButtonClickHandler}>
                        Back
                    </button>
                </div>
            </div>
        </div>
    </>
};

export default Employer;