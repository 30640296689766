import {getPosition, resize} from "../utils";
import {LoaderFunctionArgs, useLoaderData, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";

export const positionLoader = async ({params}: LoaderFunctionArgs) => {
    let data = null;
    const response = await getPosition(params.employer, params.position);
    if (response.status === 200) {
        data = response.data
    }
    return {data}
};

const List = (props: any) => (
    <ul>
        {props.responsibilities.map((item: string) => <ListItem item={item}/>)}
    </ul>
);

const ListItem = (props: any) => <li key={props.item}>{props.item}</li>;

const Position = (_props: any) => {
    const navigate = useNavigate();
    const {data}: any = useLoaderData()
    let technologies: string = "";
    const backButtonClickHandler = () => {
        navigate(-1);
    }

    useEffect(() => {
        resize().then();
    }, []);

    data.technologies.forEach((technology: string) => {
        technologies = technologies + ", " + technology
    });

    return <div className='container-fluid'>
        <div className='row'>
            <div className='col-12'>
                <h2> {data.employer}</h2>
            </div>
        </div>
        <hr/>
        <div className='row'>
            <div className='col-12'>
                <h4>{data.title}, <span className='subtitles'>{data.location} {data.subtitle}</span></h4>
                <span className='col-2 col-sm-3 col-r'>{data.dates}</span>
            </div>
        </div>
        <hr/>
        <div className='row'>
            <div className='col-12'>
                <h6 className='ui-text'>Technologies</h6>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <ul>
                    <li key='0'>{technologies.substring(2)}</li>
                </ul>
            </div>
        </div>
        <hr/>
        <div className='row'>
            <div className='col-8'>
                <h6 className='ui-text'>Responsibilities</h6>
            </div>
            <div className='col-4'>&nbsp;</div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <ul>
                    {data.responsibilities.map((responsibility: string | Array<string>) => (
                        (Array.isArray(responsibility)) ? (<List responsibilities={responsibility}/>) :
                            <ListItem item={responsibility}/>))}
                </ul>
            </div>
        </div>
        <hr/>
        <div className='row'>
            <div className='col-8'>
                <h6 className='ui-text'>Accomplishments</h6>
            </div>
            <div className='col-4'>&nbsp;</div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <ul>
                    {data.accomplishments.map((accomplishment: string) => <li
                        key={accomplishment}>{accomplishment}</li>)}
                </ul>
            </div>
        </div>
        <br/>
        <div className='row'>
            <div className='col-12'>
                <button type="button" className='btn btn-primary' onClick={backButtonClickHandler}>
                    Back
                </button>
            </div>
        </div>
    </div>
};

export default Position;