import React from "react";
import {IEmployer} from "../interfaces/iemployer";
import EmployerLink from "./employer-link";

const EmployerLinks = (props: any) => {
    let links: any = [];
    props.data.map((employer: IEmployer, index: number) => links.push(<EmployerLink employer={employer} index={index}/>))
    return <>{links}</>

}

export default EmployerLinks;