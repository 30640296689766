import {getEmployers, resize, setActiveLink} from "../utils";
import {useLoaderData, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import EmployerLinks from "./employer-links";

export const employersLoader = async () => {
    let data:any;
    const response = await getEmployers();
    data = (response.status === 200) ? response.data : [];
    return {data};
};

const Employers = (_props: any) => {
    const navigate = useNavigate();
    const {data}: any = useLoaderData();
    const backButtonClickHandler = () => navigate(-1);

    useEffect(() => {
        resize().then();
    }, []);

    setActiveLink('career');

    return <>
        <h2>Career</h2>
        <hr/>
        <ul>
            <EmployerLinks data={data}/>
        </ul>
        <br/>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <button type="button"
                            className='btn btn-primary'
                            onClick={backButtonClickHandler}>
                        Back
                    </button>
                </div>
            </div>
        </div>
    </>
};

export default Employers;