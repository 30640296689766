import React, {useEffect} from "react";
import {onLinkClicked, resize} from "../utils";
import {Link} from "react-router-dom";

const Index = (_props: any) => {
    useEffect(() => {
        resize().then();
    }, []);
    return (
        <div id="zero-state">
            <h1>Michael Kroeze</h1>
            <hr/>
            <Link id='nav-home' to={'./career'} onClick={onLinkClicked}>Career</Link>
            <hr/>
            <h5>Resumes</h5>
            <a target='_blank' href='/data/michael_kroeze_resume_condensed.pdf'>Michael Kroeze - resume
                condensed - 2023</a>
            <br/>
            <br/>
            <a target='_blank' href='/data/michael_kroeze_resume.pdf'>Michael Kroeze - resume - 2023</a>
        </div>
    )
};

export default Index;