import Gutter from "./gutter";
import React from "react";
import {Outlet} from "react-router-dom";

const Frame = (_props: any) => {
    return <div className='container-fluid frame'>
        <div className='row'>
            <Gutter/>
            <div className='content col-lg-8 col-sm-12'>
                <div className='content-area'><Outlet/></div>
            </div>
            <Gutter/>
        </div>
    </div>
};

export default Frame;

