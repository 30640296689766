import {Link} from "react-router-dom";
import React from "react";

const PositionLink = (props: any) => (
    <li key={props.index} className='li-large'>
        {
            // @ts-ignore
            <Link to={`./${props.position.link}`}>{props.position.title}</Link>
        }
        <span>,&nbsp;{props.position.location}</span>
        <div className='m-span'>&nbsp;{props.position.dates}</div>
    </li>
);

export default PositionLink;