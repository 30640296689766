import {Link} from "react-router-dom";
import React from "react";

const onLinkClicked = (ev: any) => {
    $(".nav-link.active").removeClass('active');
    $('#nav-' + ev.target.id).addClass('active');
}

const EmployerLink = (props: any) => {
    return (<li key={props.employer.link || Math.random} className='li-large'>
        <Link id={props.employer.link}
              to={`./${props.employer.link}`}
              onClick={onLinkClicked}>{props.employer.name}</Link>
    </li>)
}

export default EmployerLink;