import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import Frame from "./frame";

const Root = (_props: any) => {
    return (
        <div className='d-flex flex-column min-vh-100'>
            <Navigation/>
            <Frame/>
            <Footer/>
        </div>
    );
};

export default Root;