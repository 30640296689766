import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React from "react";
import {Link, useLoaderData} from "react-router-dom";
import {IEmployer} from "../interfaces/iemployer";
import {onLinkClicked} from "../utils";

const Navigation = (_props: any) => {
    const {data}: any = useLoaderData();
    return (
        <Navbar id='header' role='navigation' bg="light" expand="lg">
            <Navbar.Brand className='navbar-brand'>
                {
                    // @ts-ignore
                    <Link id='nav-home' to='/' className='navbar-brand' onClick={onLinkClicked}>Michael Kroeze</Link>
                }
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {
                        // @ts-ignore
                        <Link id=' nav-career' to='/career' className=' nav-link active'
                              onClick={onLinkClicked}>Career</Link>
                    }
                    {data.map((employer: IEmployer) => (
                        // @ts-ignore
                        <Link id={`nav-${employer.link}`} to={`/career/${employer.link}`}
                              key={employer.link} className=' nav-link'
                              onClick={onLinkClicked}>{employer.display}</Link>))
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Navigation;