import axios, {AxiosResponse} from "axios";
import $ from 'jquery';

export const resize = async () => {
    const windowHeight: number = $(window).innerHeight() || 0;
    const headerHeight: number = $('#header').outerHeight() || 0;
    const footerHeight: number = $('#footer').outerHeight() || 0

    const height = windowHeight - (headerHeight + footerHeight) - 2;
    $('.content').height(height);
};

export const setActiveLink = (employer: any) => {
    $(".nav-link.active").removeClass('active');
    $(`#nav-${employer}`).addClass('active');
}

export const onLinkClicked = (ev: any) => {
    // @ts-ignore
    $("#basic-navbar-nav").collapse("show");
    $(".nav-link.active").removeClass('active');
    $(ev.target).addClass('active');
}

export const getEmployers = async (): Promise<AxiosResponse<any>> =>
    await axios.get(`/data/employers.json`);

export const getEmployer = async (employer: string | undefined): Promise<AxiosResponse<any>> =>
    await axios.get(`/data/${employer}/positions.json`);

export const getPosition = async (employer: string | undefined, position: string | undefined): Promise<AxiosResponse<any>> =>
    await axios.get(`/data/${employer}/${position}/position.json`);

let timerId: NodeJS.Timeout;
$(window).resize(() => {
    if (timerId !== undefined) {
        clearTimeout(timerId);
    }
    timerId = setTimeout(() => resize(), 1);
});