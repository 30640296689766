import React from "react";
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Employers, {employersLoader} from "./routes/employers";
import Employer, {employerLoader} from "./routes/employer";
import Position, {positionLoader} from "./routes/position";
import Index from "./routes";
import 'bootstrap/dist/css/bootstrap.css';
import "./stylesheets/index.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        loader: employersLoader,
        children: [
            {index: true, element: <Index/>},
            {
                path: "/career",
                element: <Employers/>,
                loader: employersLoader,
            },
            {
                path: "/career/:employer",
                element: <Employer/>,
                loader: employerLoader,
            },
            {
                path: "career/:employer/:position",
                element: <Position/>,
                loader: positionLoader,
            }
        ]
    },
]);

// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);